<template>
  <div>
    <b-alert
      show
      variant="light"
      class="alert alert-custom alert-white alert-shadow fade show gutter-b"
    >
      <div class="alert-text">
          <b-form @submit.prevent="saveData" @reset="onReset">
          <b-form-group
            id="input-group-1"
            label="Email address:"
            label-for="input-1"
            description="We'll never share your email with anyone else."
          >
            <b-form-input
              v-model="form.email"
              type="email"
              required
              placeholder="Enter email"
            ></b-form-input>
          </b-form-group>
          <b-form-group id="input-group-6" label="Your Password:" label-for="input-2">
            <b-form-input
              v-model="form.password"
              type="text"
              required
            ></b-form-input>
          </b-form-group>
          <b-form-group id="input-group-6" label="Enter Token:" label-for="input-2">
            <b-form-input
              v-model="form.token"
              type="text"
              required
            ></b-form-input>
          </b-form-group>
          <b-button type="submit" variant="primary">Submit</b-button>
          <b-button type="reset" variant="danger">Reset</b-button>
        </b-form>
      </div>
    </b-alert>
  </div>
</template>

<script>
import Services from "@/core/services/psikologi-api/Services";
import ApiService from "@/core/services/api.service";
import Swal from 'sweetalert2'

export default {
  data() {
    return {
      form: {
        email: '',
        password: '',
        token: '',
      },
      education: [],
      agendaid: null
    }
  },
  methods: {
    onReset() {
      this.form.email = '';
      this.form.password = '';
      this.form.token = '';
    },
    saveData() {
  var mydata = {
    email: this.form.email,
    password: this.form.password
  };

  let contentType = `application/x-www-form-urlencoded`;
  const qs = require("qs");

  return new Promise((resolve, reject) => {
    Services.PostData(
      ApiService,
      "loginparticipant",
      qs.stringify(mydata),
      contentType,
      response => {
        if (response.status) {
          Swal.fire({
            title: "",
            html: "Login Berhasil",
            icon: "success",
            heightAuto: true,
            timer: 180000
          });
          var ParticipantID = response.data.ParticipantID;
          var TypeTestID = this.form.token;
          var Number = response.data.Number;
          this.$router.push(`/question/${ParticipantID}/${TypeTestID}/${Number}`);
        } else {
          Swal.fire({
            title: "",
            text: response.data.error,
            icon: "info",
            heightAuto: true,
            timer: 1500
          });
        }
      },
      error => {
        console.error('Error during API call:', error);
      }
    );
  });
}

  },
  mounted() {
    var education = this.$store.state.mEducation.master_Education;
    this.education = education.map(item => ({
      text: item.EducationName,
      value: item.EducationID
    }));
    this.agendaid = this.$route.params.agendaid;
  }
}
</script>


